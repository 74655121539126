<template>
  <div class="withdrawalRecord">
    <div class="merch-search">
      <div class="search-box">
        <!-- 退款对象,退款单号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款单号:</div> -->
          <el-input
            placeholder="请输入退款单号"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
        <!-- 退款对象,订单号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">订单号:</div> -->
          <el-input
            placeholder="请输入订单号"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
         <!-- 退款对象,主订单号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">主订单号:</div> -->
          <el-input
            placeholder="请输入订单号"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
         <!-- 退款对象,退款流水号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款流水号:</div> -->
          <el-input
            placeholder="请输入退款流水号"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
         <!-- 退款对象,会员名称 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">会员名称:</div> -->
          <el-input
            placeholder="请输入会员名称"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
         <!-- 退款对象,共享供应商 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">共享供应商名称:</div> -->
          <el-input
            placeholder="请输入共享供应商"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
         <!-- 退款对象,销售供应商 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">销售供应商名称:</div> -->
          <el-input
            placeholder="请输入销售供应商"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
        <!-- 退款类型 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">全部退款类型:</div> -->
          <el-select
            v-model="searchData.withdrawalType"
            placeholder="全部退款类型"
          >
            <el-option
              v-for="item in withdrawalTypeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 退款进度 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">提现进度:</div> -->
          <el-select
            v-model="searchData.withdrawalProgress"
            placeholder="全部状态"
          >
            <el-option
              v-for="item in withdrawalProgressList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 退款对象,退款单号 -->
        <!-- <div class="sch-1 sch-2">
          <div class="sch-title">提现单号:</div>
          <el-input
            placeholder="请输入提现单号"
            v-model="searchData.orderNo"
          ></el-input>
        </div> -->
        <!-- 退款时间 -->
        <div class="sch-1 sch-2 sch-3">
          <!-- <div class="sch-title">退款时间:</div> -->
          <el-date-picker
            v-model="searchData.createTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="退款单开始时间"
            end-placeholder="退款单结束时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <!-- 搜索 -->
        <div class="inquire">
          <el-button
            type="primary"
            size="mini"
            @click="getBusinessList(searchData)"
          >
            查询
          </el-button>
        </div>
        <!-- 重置 -->
        <div class="reset ">
          <el-button
            type="primary"
            size="mini"
            @click="getBusinessList(searchData)"
          >
            重置
          </el-button>
        </div>
      </div>
    </div>

       <!-- 表格 --> 
    <div class="table">
      <el-table :data="tableData1" border style="width: 100%">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="70"
        />
        <!-- 退款单号/时间 -->
        <el-table-column
          :key="1"
          prop="user_name"
          label="退款单号/时间"
          width="150"
          align="center"
        />
        <!-- 退款类型 -->
        <el-table-column
          :key="2"
          prop="user_name"
          label="退款类型"
          width="150"
          align="center"
          v-if="
            this.itemdata == '货款' || this.itemdata == '退款' ? false : true
          "
        />
        <!-- 会员 -->
        <el-table-column
          :key="3"
          prop="user_name"
          label="会员"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        />
        <!-- 共享供应商 -->
        <el-table-column
          :key="4"
          prop="user_name"
          label="共享供应商"
          width="150"
          align="center"
        />
        <!-- 销售供应商 -->
        <el-table-column
          :key="5"
          prop="user_name"
          label="销售供应商"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.date }}</p>
          </template>
        </el-table-column>
        <!-- 退款流水号/时间 -->
        <el-table-column
          :key="6"
          prop="user_name"
          label="退款流水号/时间"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <!-- 售后单号/时间 -->
        <el-table-column
          :key="7"
          prop="user_name"
          label="售后单号/时间"
          width="280"
          align="center"
        />
        <!-- 主订单号 -->
        <el-table-column
          :key="8"
          prop="user_name"
          label="主订单号"
          width="150"
          align="center"
        />
        <!-- 运费（元） -->
        <el-table-column
          :key="9"
          prop="user_name"
          label="运费（元）"
          width="280"
          align="center"
        />
        <!-- 货款（元） -->
        <el-table-column
          :key="10"
          prop="user_name"
          label="货款（元）"
          width="150"
          align="center"
        />
        <!-- 商品名称 -->
        <el-table-column
          :key="11"
          prop="user_name"
          label="商品名称"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        />
        <!-- 规格 -->
        <el-table-column
          :key="12"
          prop="user_name"
          label="规格"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        />
        <!-- 生产厂家 -->
        <el-table-column
          :key="13"
          prop="user_name"
          label="生产厂家"
          width="200"
          align="center"
          v-if="
            this.itemdata == '运费' || this.itemdata == '退款' ? false : true
          "
        />
        <!-- 单价（元） -->
        <el-table-column
          :key="14"
          prop="user_name"
          label="单价（元）"
          width="150"
          align="center"
          v-if="
            this.itemdata == '运费' || this.itemdata == '退款' ? false : true
          "
        />
        <!-- 订单数量/退款数量 -->
        <el-table-column
          :key="15"
          prop="user_name"
          label="订单数量/退款数量"
          width="150"
          align="center"
          v-if="
            this.itemdata == '手续费' || this.itemdata == '佣金' ? true : false
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <!-- 佣金（元/件） -->
        <el-table-column
          :key="16"
          prop="user_name"
          label="佣金（元/件）"
          width="150"
          align="center"
          v-if="this.itemdata == '佣金' ? true : false"
        />
        <!-- 佣金小计（元） -->
        <el-table-column
          :key="17"
          prop="user_name"
          label="佣金小计（元）"
          width="150"
          align="center"
          v-if="this.itemdata == '佣金' ? true : false"
        />
        <!-- 订单数量 -->
        <el-table-column
          :key="18"
          prop="user_name"
          label="订单数量"
          width="150"
          align="center"
          v-if="this.itemdata == '货款' ? true : false"
        />
        <!-- 平台手续费（元/件） -->
        <el-table-column
          :key="19"
          prop="user_name"
          label="平台手续费（元/件）"
          width="150"
          align="center"
          v-if="
            this.itemdata == '货款' || this.itemdata == '手续费' ? true : false
          "
        />
        <!-- 手续费小计（元/件） -->
        <el-table-column
          :key="20"
          prop="user_name"
          label="手续费小计（元/件）"
          width="150"
          align="center"
          v-if="this.itemdata == '手续费' ? true : false"
        />
        <!-- 商品佣金（元/件） -->
        <el-table-column
          :key="21"
          prop="user_name"
          label="商品佣金（元/件）"
          width="150"
          align="center"
          v-if="
            this.itemdata == '手续费' || this.itemdata == '货款' ? true : false
          "
        />
        <!-- 结算合计（元） -->
        <el-table-column
          :key="22"
          prop="user_name"
          label="结算合计（元/件）"
          width="150"
          align="center"
          v-if="this.itemdata == '货款' ? true : false"
        />

        <!-- 状态 -->
        <el-table-column
          :key="23"
          prop="user_name"
          label="状态"
          width="100"
          align="center"
          fixed="right"
        />
      </el-table>
      <div class="paging">
        <!-- sizes,  -->
        <el-pagination
          background
          layout=" total,prev, pager, next, jumper"
          :page-size="1"
          :total="10"
          :current-page.sync="currentPage1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchData: {
        withdrawalProgress: "",
        withdrawalType: "",
        orderNo: "",
        createTime: "",
      },
      // 时间选择配置项
      pickerOptions: {
        shortcuts: [
          {
            text: "当月月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // console.log(end,'end',start,"start");
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 提现类型
      withdrawalTypeList: [
        { name: "个人提现", type: 1 },
        { name: "企业提现", type: 2 },
      ],
      withdrawalProgressList: [
        { name: "处理中", type: 1 },
        { name: "成功", type: 2 },
      ],
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.withdrawalRecord {
  // 搜索行样式
  .merch-search {
    margin-top: 1%;
    width: 100%;
    .search-box {
      display: flex;
      flex-wrap: wrap;
    }
    .sch-1 {
      margin-top: 10px;
      width: 13%;
      min-width: 150px;
      display: flex;

      .sch-title {
        width: 100px;
        min-width: 100px;
        height: 100%;
        font-size: 13px;
        background-color: #f5f7fa;
        color: #909399;
        border: 1px solid #dcdfe6;
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 5px !important;
      }
    }
    .sch-2 {
      margin-right: 1%;
    }
    .sch-3{
      width: 27%;
      min-width: 300px;
      .el-date-editor--datetimerange.el-input__inner{
          width: 100%;
      }
    }
        .inquire,.reset{
            margin-top: 10px;
            width: 100px;
            height: 40px;
            margin-right: 1%;
            button{
                width: 100%;
                height: 100%;
            }
        }
  }
  .dataTable {
    margin-top: 20px;
  }
  .footer {
    text-align: right;
    margin-top: 20px;
  }
  .paging {
  margin-top: 10px;
  text-align: right;
  }
  .table {
  margin-top: 20px;
  }
}
</style>